.auth-page {
    height: calc(100vh - 80px);
    padding: 20px 80px;
    margin-top: 80px;
    background-color: #f9f9f9;
    background-image: url(../assets/images/illustrations/register.svg);
    background-position: 50px center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    position: relative;
}

.auth-form {
    width: 35%;
    margin-left: 55%;
    height: 100%;
    border-radius: 3px;
    background-color: #FFFFFF;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px 40px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
    scrollbar-color: #00CCFF #CCF5FF;
    scrollbar-width: thin;
}

.auth-form::-webkit-scrollbar {
    width: 6px;
    background-color: #CCF5FF;
}
.auth-form::-webkit-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
}

.auth-form::-moz-scrollbar {
    width: 6px;
    background-color: #CCF5FF;
}
.auth-form::-moz-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
}

.auth-form h1 {
    color: #600080;
    margin: 0 0 20px;
    padding: 5px 10px;
    border-bottom: 2px solid #F2CCFF;
}

.auth-page .auth-message {
    padding: 10px;
    margin: -15px 0 20px;
    font-size: 14px;
    line-height: 1.4;
    border-radius: 3px;
    font-weight: 300;
}

.auth-page .auth-error {
    color: #FF0066;
    background-color: #FFE6F0;
    border: 1px solid #FF0066;
}
.auth-page .auth-success {
    color: #00CC00;
    background-color: #F9F9F9;
    border: 1px solid #00CC00;
}

.auth-page .auth-message .lnk {
    font-weight: 600;
    text-decoration: none;
}

.auth-page .auth-message .lnk:hover {
    text-decoration: underline;
}

.auth-page .submit-box {
    margin: 20px 0 40px;
}

.auth-page .submit-box button {
    background-color: #00CCFF;
    padding: 10px 25px;
    display: inline-block;
    min-width: 150px;
    border: none;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 1px;
    outline: 0;
    font-weight: 600;
    transition: all 0.2s;
    cursor: pointer;
}

.auth-page .submit-box button:disabled {
    background-color: #B3F0FF;
}

.auth-page .submit-box .submit {
    background-color: #730099;
}

.auth-page .submit-box .submit:hover {
    background-color: #600080;
    transition: all 0.2s;
}

.auth-page .submit-box .submit:active {
    background-color: #4D0066;
}

.auth-page .submit-box .submit:disabled {
    background-color: #ECB3FF;
}

.auth-page .submit-box .forgot {
    text-decoration: none;
    color: #CC33FF;
    font-size: 14px;
    display: inline-block;
    letter-spacing: 1px;
    padding: 11px 0;
    margin-left: 10px;
    transition: all 0.2s;
}

.auth-page .submit-box .forgot:hover {
    color: #600080;
}

.auth-page .submit-box .text {
    margin: 20px 0 40px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #333333;
}

.auth-page .submit-box .text a {
    text-decoration: none;
    color: #9900CC;
}

.auth-page .submit-box .text a:hover {
    text-decoration: underline;
}

.auth-page .terms-agreement {
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    display: block;
}

.auth-page .terms-agreement input {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-70%);
}

.auth-page .terms-agreement button {
    background: transparent;
    border: none;
    padding: 0;
    font-weight: 600;
    letter-spacing: 1px;
    white-space: normal;
    color: #600080;
    cursor: pointer;
}

.auth-page .terms-agreement button:hover {
    text-decoration: underline;
}

.signup-modal-backdrop {
    position: fixed;
    top: -110%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}

.signup-modal-backdrop.active {
    top: 0;
}

.signup-modal {
    min-height: 400px;
    width: 30%;
    margin: 100px 35% 0;
    padding: 30px 40px;
    transform: translateY(-400px);
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.5;
    color: #333333;
    background-color: #FFFFFF;
    background-image: linear-gradient(#FFFFFF, #F9F9F9);
    box-shadow: 0px 15px 40px rgba(0,0,0,0.3);
    transition: all 0.3s ease-out;
}

.signup-modal.active {
    transform: translateY(0);
}

.signup-modal h2 {
    color: #00CCFF;
}

.signup-modal .cancel {
    display: block;
    margin: 50px auto 25px;
    width: 120px;
    background-color: #00CCFF;
    color: #FFFFFF;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    padding: 10px 30px;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s;
}

.signup-modal .cancel:hover {
    background-color: #00B8E6;
}

@media only screen and (max-width: 1025px) {
    .auth-page {
        padding: 20px;
    }

    .auth-form {
        width: 38%;
        margin-left: 50%;
        padding: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .auth-page {
        min-height: 700px;
        background-position: left center;
    }

    .auth-form {
        width: 40%;
    }
}

@media only screen and (max-width: 769px) {
    .auth-form {
        width: 50%;
        margin-left: 40%;
        padding: 20px;
    }
}

@media only screen and (max-width: 412px) {
    .auth-page {
        min-height: calc(100vh - 80px);
        padding: 10px;
        background-image: none;
    }

    .auth-form {
        width: 100%;
        margin-left: 0;
        padding: 20px;
        background-color: rgba(255,255,255,0.9);
    }
}