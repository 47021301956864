.report-box {
    padding: 15px;
    float: left;
    width: 100%;
}

.report-box.w-40 {
    width: 40%;
}

.report {
    min-height: calc(100vh - 180px);
    padding: 20px 20px 80px;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
    transition: all 0.3s;
}

.report-intro {
    padding: 20px 30px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #F9F9F9;
    margin: -20px 0 20px;
    letter-spacing: 1px;
    font-weight: 300;
    min-height: 200px;
}

.report-intro .desc {
    width: 50%;
    margin: 5px 0 10px;
}

.report-intro .label {
    color: #999999;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 5px;
}

.report-intro .value {
    color: #00CCFF;
    font-weight: 600;
    font-size: 22px;
}

.report-intro .big .value {
    font-size: 40px;
    color: #730099;
}

.report-intro .big .value .not-ready {
    color: #333333;
}

@media only screen and (max-width: 1025px) {
    .report-intro .big .value {
        font-size: 34px;
    }
}

@media only screen and (max-width: 992px) {
    .report-intro .big .value {
        font-size: 30px;
    }
}

@media only screen and (max-width: 769px) {
    .report-intro {
        padding: 20px;
    }

    .report-intro .big .value {
        font-size: 24px;
    }

    .report-intro .value {
        font-size: 16px;
    }
}

@media only screen and (max-width: 412px) {
    .report {
        padding: 20px 10px 80px;
    }

    .report-intro {
        padding: 10px;
    }

    .report-intro .desc.big {
        width: 100%;
    }

    .report-intro .label {
        font-size: 12px;
    }

    .report-intro .big .value {
        font-size: 20px;
    }

    .report-intro .value {
        font-size: 14px;
    }
}

.report-section {
    padding: 5px 20px;
    transition: all 0.3s;
}

.report-section .title {
    color: #333333;
    font-weight: 600;
    font-size: 24px;
    margin: 0 0 10px;
    padding: 5px 10px;
    letter-spacing: 1px;
    border-bottom: 1px solid #E6E6E6;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
}

.report-section .title.active {
    color: #730099;
    margin: 0;
    border-bottom: 1px solid #B3B3B3;
}

.report-section .title:hover {
    color: #730099;
}

.report-section .title .la {
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 15px;
    transition: all 0.3s;
}

.report-section .title.active .la {
    transform: rotate(180deg);
}

.report-text {
    padding: 15px 10px;
    margin-bottom: 10px;
    background-color: #F9F9F9;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: 300;
    color: #333333;
}

.report-assessment {
    position: relative;
    background-color: #F9E6FF;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.report-assessment .question {
    width: 65%;
    height: 100%;
    background-color: #FFFFFF;
    padding: 10px;
}

.report-assessment .answer {
    width: 35%;
    font-size: 20px;
    line-height: 1.2;
    padding: 10px 15px;
    font-weight: 600;
    color: #000000;
}

.report-assessment .answer-button {
    text-decoration: none;
    color: #333333;
    font-weight: 500;
    font-size: 15px;
    display: block;
    transition: all 0.2s;
    cursor: pointer;
    padding: 0 10px;
    background-color: #FFFFFF;
    border: 1px solid rgba(0,0,0,0.2);
}

.report-assessment .answer-button .la {
    font-size: 20px;
    color: #730099;
}

.report-assessment .answer a:hover {
    color: #730099;
}

@media only screen and (max-width: 769px) {
    .report-section {
        padding: 5px 0;
    }
    
    .report-section .title {
        font-size: 20px;
    }
    
    .report-text {
        padding: 10px 10px 20px;
    }

    .profile-text .desc {
        width: 100%;
    }

    .report-text .label {
        font-size: 12px;
    }
    
    .report-text .value {
        font-size: 18px;
    }

    .report-assessment .question {
        font-size: 14px;
        width: 70%;
    }
    
    .report-assessment .answer {
        font-size: 14px;
        width: 30%;
        padding: 10px;
    }
    
    .report-assessment .answer a {
        font-size: 14px;
    }
    
    .report-assessment .answer a .la {
        font-size: 18px;
    }
}

@media only screen and (max-width: 412px) {
    .report-assessment .question {
        width: 100%;
    }

    .report-assessment .answer {
        font-size: 14px;
        width: 100%;
        padding: 10px;
    }
    
    .report-assessment .answer a {
        font-size: 14px;
    }
    
    .report-assessment .answer a .la {
        font-size: 18px;
    }
}