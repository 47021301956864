.how {
    padding: 200px 80px 100px;
    background-color: #FFFFFF;
    text-align: center;
}

.how .title {
    color: #600080;
    font-size: 40px;
}

.how .item {
    width: 33.333333%;
    padding: 0 20px;
    margin-top: 20px;
}

.how .item img {
    display: inline-block;
    height: 120px;
}

.how .item h3 {
    color: #9900CC;
}

.how .item p {
    line-height: 1.8;
    font-size: 16px;
    letter-spacing: 1px;
    color: #333333;
    font-weight: 400;
}

.how .just-2 {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 40px 0 10px;
}

.how .just-2 .la {
    color: #9900CC;
}

@media only screen and (max-width: 992px) {
    .how {
        padding: 120px 20px 100px;
    }
}

@media only screen and (max-width: 769px) {
    .how .item {
        padding: 0 10px;
    }
}

@media only screen and (max-width: 412px) {
    .how .title {
        font-size: 28px;
    }

    .how .item {
        width: 100%;
    }

    .how .just-2 .link {
        display: block;
        margin: 20px auto 0;
        width: 60%;
    }
}