.nav-item {
    display: block;
    height: 60px;
    line-height: 60px;
    margin: 10px 0;
    white-space: nowrap;
    font-size: 16px;
    color: rgba(255,255,255,0.5);
    letter-spacing: 1px;
    transition: all 0.2s ease-out;
    cursor: pointer;
    text-decoration: none;
}

.nav-item:hover {
    color: #FFFFFF;
}

.nav-item.active {
    color: #FFFFFF;
    background-color: rgba(255,255,255,0.1);
}

.nav-item .la {
    font-size: 30px;
    display: block;
    padding: 15px 0;
    width: 80px;
    height: 60px;
    box-sizing: border-box;
    text-align: center;
    color: rgba(255,255,255,0.8);
    float: left;
}

.nav-item:hover .la {
    color: #FFFFFF;
}

.nav-item.active .la {
    padding: 15px 2px 15px 0;
    color: #FFFFFF;
    border-left: 2px solid #FFFFFF;
}

@media only screen and (max-width: 412px) {
    .nav-item {
        height: 40px;
        line-height: 40px;
        font-size: 13px;
    }

    .nav-item .la {
        font-size: 20px;
        padding: 10px 0;
        width: 40px;
        height: 40px;
    }

    .nav-item.active .la {
        padding: 10px 2px 10px 0;
    }
}