.card-box {
    padding: 15px;
}

.card {
    height: 200px;
    padding: 15px;
    float: left;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    text-decoration: none;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
    transition: all 0.3s;
}

.card:hover {
    background-color: #FFFFFF;
    box-shadow: none;
}

/* Widths */
.card-box.w-1of3 {
    width: 33.33333333%;
}
.card-box.w-1of2 {
    width: 50%;
}
.card-box.w-20 {
    width: 20%;
}
.card-box.w-25 {
    width: 25%;
}
.card-box.w-40 {
    width: 40%;
}
.card-box.w-60 {
    width: 60%;
}
.card-box.w-80 {
    width: 80%;
}

/* Heights */
.card.h-150 {
    height: 150px;
}
.card.h-200 {
    height: 200px;
}
.card.h-250 {
    height: 250px;
}
.card.h-300 {
    height: 300px;
}
.card.h-350 {
    height: 350px;
}
.card.h-400 {
    height: 400px;
}

@media only screen and (max-width: 992px) {
    .card-box {
        padding: 10px;
    }
}

@media only screen and (max-width: 769px) {
    .card-box.w-50-s{
        width: 50%;
    }
}

@media only screen and (max-width: 412px) {
    .card-box.w-1of3, .card-box.w-1of2,
    .card-box.w-20, .card-box.w-25 {
        width: 100%;
    }

    .card-box.w-50-s{
        width: 50%;
    }

    .card {
        border-radius: 5px;
    }

    .w-50-s .card {
        padding: 10px;
    }
}

/* Alignment */
.card.text-left {
    text-align: left;
}
.card.text-center {
    text-align: center;
}
.card.text-right {
    text-align: right;
}

/* Card Icon */
.card-icon {
    float: unset;
}

.card-icon .la {
    display: inline-block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 22px;
    font-size: 30px;
    text-align: center;
    border: 1px solid rgba(0,0,0,0.1);
}

.card-icon .la.purple {
    color: #9900CC;
    background-color: #F9E6FF;
}
.card-icon .la.cyan {
    color: #00A3CC;
    background-color: #E6FAFF;
}
.card-icon .la.gray {
    color: #333333;
    background-color: #F2F2F2;
}
.card-icon .la.red {
    color: #FF0066;
    background-color: #FFE6f0;
}
.card-icon .la.green {
    color: #00CC00;
    background-color: #E6FFE6;
}
.card-icon .la.yellow {
    color: #E6B800;
    background-color: #FFF5CC;
}

/* Card Headers */
.card-header0 {
    margin: 8px 0;
    color: #CCCCCC;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
}

.card-header1 {
    margin: 15px 0 0;
    color: #333333;
    font-size: 20px;
    font-weight: 400;
}

.card-header2 {
    margin: 0;
    color: #000000;
    font-size: 50px;
}

.card-header3 {
    margin: 5px 0;
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    transition: all 0.3s;
}
.card-header3.purple {
    color: #9900CC;
}
.card-header3.cyan {
    color: #00A3CC;
}
.card-header3.gray {
    color: #333333;
}
.card-header3.red {
    color: #FF0066;
}
.card-header3.green {
    color: #00CC00;
}
.card-header3.yellow {
    color: #E6B800;
}

.card:hover .card-header3 {
    color: #333333;
}

@media only screen and (max-width: 769px) {
    .card-header1 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 412px) {
    .card-header0 {
        margin: 8px 0;
        font-size: 16px;
    }
}

/* Card Link */
.card-link {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: block;
    margin-top: 3px;
    padding: 5px;
    transition: all 0.2s;
}

.card-link.purple {
    color: #9900CC;
}
.card-link.purple:hover {
    background-color: #F9E6FF;
}

.card-link.cyan {
    color: #00A3CC;
}
.card-link.cyan:hover {
    background-color: #E6FAFF;
}

.card-link.gray {
    color: #333333;
}
.card-link.gray:hover {
    background-color: #F2F2F2;
}

.card-link.red {
    color: #FF0066;
}
.card-link.red:hover {
    background-color: #FFE6f0;
}

.card-link.green {
    color: #00CC00;
}
.card-link.green:hover {
    background-color: #E6FFE6;
}

.card-link.yellow {
    color: #E6B800;
}
.card-link.yellow:hover {
    background-color: #FFF5CC;
}