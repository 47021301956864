.support-ticket-box {
    padding: 15px;
}

.support-ticket {
    padding: 20px 10px;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
    position: relative;
    transition: all 0.3s;
}

.support-ticket.active {
    padding: 0 10px 20px;
}

.support-ticket .support-ticket-head {
    font-size: 20px;
    position: relative;
    font-weight: 600;
    color: #F9F9F9;
    letter-spacing: 1px;
    padding: 10px 25px;
    background-color: #730099;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s;
    text-decoration: none;
}

.support-ticket a.support-ticket-head:hover {
    text-decoration: underline;
}

.support-ticket.active .support-ticket-head {
    color: #730099;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #F9F9F9;
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.support-ticket .support-ticket-head .la {
    position: absolute;
    font-size: 16px;
    top: 14px;
    right: 20px;
    transition: all 0.3s;
}

.support-ticket.active .support-ticket-head .la {
    transform: rotate(180deg);
}

.support-ticket-form {
    float: left;
    width: 100%;
}

.support-ticket-form button {
    padding: 10px 25px;
    border: none;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #730099;
    font-size: 16px;
    letter-spacing: 1px;
    outline: 0;
    font-weight: 600;
    transition: all 0.2s;
    cursor: pointer;
    float: right;
    margin-top: 10px;
}

.support-ticket-form button:hover {
    background-color: #600080;
    transition: all 0.2s;
}

.support-ticket-form button:active {
    background-color: #4D0066;
}

.support-ticket-form button:disabled {
    background-color: #ECB3FF;
}

.support-ticket-sent {
    text-align: center;
    padding: 60px 30px;
    color: #999999;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 1px;
}

/* TICKET */
.ticket-box {
    padding: 0px 15px;
}

.ticket {
    padding: 80px 5px 90px 10px;
    height: calc(100vh - 120px);
    width: 50%;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
    position: relative;
    transition: all 0.3s;
}

.ticket.closed {
    padding: 80px 5px 20px 10px;
}

.ticket-head {
    width: unset;
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ticket-name {
    font-size: 20px;
    font-weight: 600;
    color: #730099;
    height: 35px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: 1px;
    padding: 10px 10px 0;
    background-color: #F9F9F9;
}

.ticket-info {
    background-color: #F2F2F2;
    font-weight: 300;
    color: #999999;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 6px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ticket-info .item {
    display: inline-block;
    margin-right: 20px;
}

.ticket-info .red {
    color: #FF0066;
}
.ticket-info .green {
    color: #00CC00;
}
.ticket-info .purple {
    color: #9900CC;
}
.ticket-info .cyan {
    color: #00A3CC;
}

@media only screen and (max-width: 769px) {
    .ticket {
        padding: 80px 5px 90px 10px;
        height: calc(100vh - 90px);
        width: 100%;
    }
}

.ticket-thread {
    height: 100%;
    padding: 10px 15px;
    overflow-y: scroll;
    scrollbar-color: #00CCFF #E6FAFF;
    scrollbar-width: thin;
}

.ticket-thread::-webkit-scrollbar {
    width: 5px;
    background-color: #E6FAFF;
    border-radius: 6px;
}
.ticket-thread::-webkit-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
}
.ticket-thread::-moz-scrollbar {
    width: 5px;
    background-color: #E6FAFF;
    border-radius: 6px;
}
.ticket-thread::-moz-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
}

.ticket-thread .message {
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    letter-spacing: 1px;
    line-height: 1.5;
    max-width: 80%;
    min-width: 160px;
    width: auto;
    position: relative;
    padding: 5px 10px 20px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: #E6FAFF;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
}

.ticket-thread .message.client {
    float: right;
    background-color: #F9E6FF;
}

.ticket-thread .message .time {
    position: absolute;
    bottom: 4px;
    right: 0;
    padding: 0 10px;
    line-height: 1;
    font-size: 10px;
    color: #999999;
}

.ticket-form {
    width: 100%;
    float: left;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 15px 0;
    background-color: #F9F9F9;
}

.ticket-form .message-field {
    width: calc(100% - 100px);
}

.ticket-form button {
    padding: 8px 5px;
    border: none;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #730099;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    transition: all 0.2s;
    cursor: pointer;
    position: absolute;
    width: 80px;
    right: 25px;
    top: 25px;
}

.ticket-form button:hover {
    background-color: #600080;
    transition: all 0.2s;
}

.ticket-form button:active {
    background-color: #4D0066;
}

.ticket-form button:disabled {
    background-color: #ECB3FF;
}

@media only screen and (max-width: 769px) {
    .ticket-thread {
        padding: 10px 10px 10px 0;
    }
}