.home-banner {
    padding: 0 80px;
    background-color: #FFFFFF;
    height: calc(100vh - 80px);
    min-height: 600px;
    background-image: url(../../assets/images/illustrations/Taxmingo.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 50% auto;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.home-banner .content {
    position: relative;
    top: 50%;
    transform: translateY(-55%);
}

.home-banner h1 {
    margin: 0 0 30px;
    font-size: 4.3vw;
    width: 55%;
    font-weight: bolder;
    line-height: 1;
    color: #9900CC;
    text-shadow: 0px 0px 1px #FFFFFF, 0px 0px 2px #FFFFFF, 0px 0px 3px #FFFFFF, 0px 0px 4px #FFFFFF, 0px 0px 5px #FFFFFF;
}

.home-banner p {
    font-weight: 400;
    font-size: 1.4vw;
    color: #000000;
    letter-spacing: 1px;
    width: 52%;
    line-height: 1.6;
    margin-bottom: 50px;
}

.banner-clients {
    height: 120px;
    background-color: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.05);
    position: absolute;
    bottom: -115px;
    width: calc(100% - 160px);
}

.banner-clients .text {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    color: #00B8E6;
}

.banner-clients .images {
    line-height: 80px;
    height: 80px;
    text-align: center;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-color: #99EBFF #E6FAFF;
    scrollbar-width: thin;
}
.banner-clients .images::-webkit-scrollbar {
    height: 2px;
    background-color: #E6FAFF;
}
.banner-clients .images:hover::-webkit-scrollbar {
    height: 8px;
}
.banner-clients .images::-webkit-scrollbar-thumb {
    background-color: #99EBFF;
}
.banner-clients .images::-moz-scrollbar {
    height: 2px;
    background-color: #E6FAFF;
}
.banner-clients .images:hover::-moz-scrollbar {
    height: 8px;
}
.banner-clients .images::-moz-scrollbar-thumb {
    background-color: #99EBFF;
}

.banner-clients .images img {
    display: inline-block;
    height: 40px;
    margin: 15px 50px;
}

@media only screen and (max-width: 992px) {
    .home-banner {
        padding: 0 30px;
        background-size: 60% auto;
    }

    .home-banner p {
        font-size: 2vw;
    }
    
    .banner-clients {
        width: calc(100% - 60px);
    }
}

@media only screen and (max-width: 769px) {
    .home-banner {
        background-position: right bottom;
        background-size: 60% auto;     
        min-height: 280px;
    }

    .home-banner h1 {
        width: 100%;
    }

    .home-banner p {
        width: 80%;
        font-size: 2vw;
        margin-bottom: 25px;
    }    
    
    .banner-clients {
        height: 100px;
    }

    .banner-clients .images {
        line-height: 60px;
        height: 60px;
    }
}

@media only screen and (max-width: 412px) {
    .home-banner {
        padding: 0 20px 70px;
        height: calc(100vh - 80px);
        background-image: none;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 90% auto;
    }

    .home-banner .content {
        transform: translateY(-50%);
    }

    .home-banner h1 {
        font-size: 10vw;
    }

    .home-banner p {
        font-size: 4vw;
        width: 100%;
        margin-bottom: 40px;
    }

    .home-banner .link {
        padding: 10px 25px;
    }

    .home-banner .link.b {
        margin: 0;
        padding: 10px;
    }

    .banner-clients {
        height: 120px;
        bottom: -60px;
        width: calc(100% - 40px);
    }
    
    .banner-clients .text {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
    }
    
    .banner-clients .images {
        width: 100%;
        line-height: 80px;
        height: 80px;
        text-align: center;
        overflow: hidden;
        overflow-x: scroll;
        white-space: nowrap;
    }

    .banner-clients .images img {
        height: 40px;
        margin: 20px;
    }
}