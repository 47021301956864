.pricing {
    padding: 30px 70px 60px;
    text-align: center;
}

.pricing .intro {
    padding: 20px;
}

.pricing .intro img {
    width: 20%;
}

.pricing .intro h1 {
    color: #600080;
    margin: 0;
}

.pricing .item {
    border-radius: 5px;
    width: calc(25% - 20px);
    margin: 0 10px;
    padding: 20px 10px 30px;
    background-color: #F2F2F2;
    position: relative;
}

.pricing .item.b {
    background-color: #E6E6E6;
}

.pricing .item .name {
    color: #600080;
    margin: 0 0 10px;
}

.pricing .item .plan {
    padding: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
    height: 200px;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 300;
    text-align: left;
}

.pricing .item .plan b {
    color: #600080;
    margin: 0;
}

.pricing .item .plan-link {
    color: #600080;
    font-weight: 600;
    cursor: pointer;
}

.pricing .item .plan-link:hover {
    text-decoration: underline;
}

.pricing .item .icon {
    font-size: 50px;
    margin: auto;
    color: #E699FF;
    display: block;
    margin-bottom: 15px;
}

.pricing .item .perk {
    border-bottom: 1px solid #F2F2F2;
    padding: 8px 5px;
}

.pricing .item .perk:last-child {
    border-bottom: none;
}

.pricing .item .price {
    font-size: 26px;
    line-height: 1;
    margin: 25px 0;
    color: #600080;
    margin-bottom: 20px;
}

.pricing .item .price .naira {
    font-weight: bold;
}

.pricing .item .price span {
    display: block;
    font-size: 16px;
}

.pricing .item .link {
    width: 160px;
    cursor: pointer;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.1);
}

.pricing .item .term {
    color: #999;
    font-size: 0.9rem;
}

@media only screen and (max-width: 1025px) {
    .pricing .item .link {
        width: 120px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 992px) {
    .pricing .item {
        width: calc(50% - 20px);
        margin: 0 10 10px;
    }
}

@media only screen and (max-width: 576px) {
    .pricing {
        padding: 20px 10px 50px;
    }
    
    .pricing .intro img {
        width: 50%;
    }

    .pricing .item {
        width: 100%;
        margin: 0 0 10px;
        height: auto;
    }

    .pricing .item .plan {
        height: auto;
    }
}