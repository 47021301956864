.dash-input-box {
    /* margin: 10px 0; */
    padding: 0;
    border-bottom: 1px solid #f9e6ff;
    background-color: #FDF6FF;
}

.dash-input-content {
    width: 40%;
    padding: 15px 10px;
    background-color: #FFFFFF;
}

.dash-input-description {
    width: 50%;
    padding: 15px;
    line-height: 1.8;
    font-size: 15px;
    letter-spacing: 1px;
    color: #666666;
    font-weight: 300;
}

.dash-input-box label {
    display: block;
    padding: 2px 15px;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 15px;
    line-height: 1.6;
}

.dash-input-box input,
.dash-input-box select,
.dash-input-box textarea {
    display: block;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.1);
    background-color: #F2F2F2;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 36px;
    max-height: 65px;
    font-size: 16px;
    line-height: 26px;
    padding: 5px 15px;
    outline: 0;
    font-weight: 400;
    letter-spacing: 1px;
    box-sizing: border-box;
    transition: all 0.2s;
}

.dash-input-box select {
    padding: 5px 15px 5px 10px;
}

.dash-input-box input:focus,
.dash-input-box select:focus,
.dash-input-box textarea:focus {
    background-color: #F9E6FF;
}

.dash-input-box .radio-input {
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    padding: 0px 15px;
    font-weight: 400;
    letter-spacing: 1px;
    box-sizing: border-box;
    transition: all 0.2s;
    overflow: hidden;
}

.dash-input-box .radio-input label {
    display: inline-block;
    font-size: 21px;
    font-weight: 600;
    height: 32px;
    padding: 5px 15px 2px;
    transition: all 0.3s;
    color: #600080;
    cursor: pointer;
    font-weight: 300;
    border-radius: 3px;
    position: relative;
}

.dash-input-box .radio-input label:hover {
    background-color: #F2CCFF;
}

.dash-input-box .radio-input input {
    display: inline-block;
    width: 26px;
    position: absolute;
    left: 0;
    opacity: 0;
}

.dash-input-box .radio-input .radio {
    display: inline-block;
    padding: 0;
    width: 22px;
    height: 22px;
    border-radius: 15px;
    border: 2px solid #600080;
    vertical-align: sub;
    transition: all 0.3s;
}

.dash-input-box .radio-input input:checked ~ .radio {
    background-color: #9900CC;
    border: 3px solid #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0,0,0,1);
}

.dash-input-box .invalid-field {
    background-color: #FFCCE0;
    border-color: #FF66A3;
}

.dash-input-box .error {
    font-size: 14px;
    color: #FF0066;
    padding: 2px 5px;
    /* font-weight: 600; */
    line-height: 1.2;
    margin-bottom: 5px;
}

.dash-input-box .error a {
    text-decoration: none;
    color: #9900CC;
}

.dash-input-box .error a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 769px) {
    .dash-input-content {
        width: 100%;
        padding: 10px 5px;
    }
    
    .dash-input-description {
        /* width: 100%;
        line-height: 1.2; */
        display: none;
    }
}

@media only screen and (max-width: 412px) {
    .dash-input-box label {
        padding: 2px 0px;
    }

    .dash-input-box .radio-input {
        padding: 0px 0px;
    }

    .dash-input-box .radio-input label {
        font-size: 18px;
        padding: 5px 7px 2px;
    }
    
    .dash-input-box .radio-input .radio {
        width: 20px;
        height: 20px;
    }
}