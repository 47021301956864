.services {
    background-color: #FBF0FF;
    padding: 100px 120px 80px;
    text-align: center;
}

.services .title {
    color: #600080;
    font-size: 40px;
}

.services-item {
    width: 50%;
    height: 400px;
    text-align: left;
    padding: 50px;
    display: inline-block;
    float: none;
}

.services-item .image {
    width: 35%;
}

.services-item .image img {
    width: 90%;
}

.services-item .text {
    width: 65%;
    color: #333333;
}

.services-item .text h2 {
    color: #9900CC;
}

.services-item .text p {
    font-size: 16px;
    line-height: 1.8;
    letter-spacing: 1px;
    font-weight: 400;
}

.services-item .summary {
    margin: 0;
    font-size: 33px;
    line-height: 1.5;
    font-weight: 300;
    color: #ecb3ff;
}

.services-item .summary .link {
    margin-top: 30px;
}

@media only screen and (max-width: 1025px) {
    .services {
        padding: 100px 30px 150px;
    }

    .services-item {
        height: 350px;
        padding: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .services {
        padding: 100px 50px 80px;
    }

    .services-item {
        height: 360px;
        padding: 30px;
    }

    .services-item .text p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 769px) {
    .services {
        padding: 100px 20px 80px;
    }

    .services-item {
        height: 450px;
        padding: 30px;
    }

    .services-item .image {
        width: 100%;
    }
    
    .services-item .image img {
        width: 40%;
    }
    
    .services-item .text {
        width: 100%;
    }
}

@media only screen and (max-width: 412px) {
    .services {
        padding: 100px 20px 80px;
    }

    .services .title {
        font-size: 28px;
    }

    .services-item {
        width: 100%;
        height: auto;
    }
    
    .services-item .text p {
        font-size: 14px;
    }
}