.App {
  /* background-color: #f9f9f9; */
  height: 100vh;
}

.link {
  display: inline-block;
  text-decoration: none;
  padding: 12px 40px;
  font-size: 18px;
  border-radius: 3px;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.2s;
}

.link.a {
  color: #FFFFFF;
  background-color: #00B8E6;
}
.link.a:hover {
  background-color: #00A3CC;
}

.link.b {
  color: #00B8E6;
  padding: 12px 20px;
  margin: 0px 20px;
}
.link.b:hover {
  text-decoration: underline;
}

button:focus {
  outline: 2px dotted rgba(255,255,255,0.5) !important;
  outline-offset: -2px;
}

.naira {
  font-family: sans-serif;
  font-weight: normal;
}