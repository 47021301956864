.nav {
    position: fixed;
    left: 80px;
    top: 100px;
    width: 80px;
    height: calc(100% - 120px);
    overflow: hidden;
    overflow-y: auto;
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
    background-color: #39004D;
    background-image: linear-gradient(135deg, #007A99, #730099);
    z-index: 100;
    transition: width 0.2s ease-out;
    scrollbar-color: rgba(255,255,255,0.5) rgba(0,0,0,0);
    scrollbar-width: thin;
}

.nav:hover {
    width: 300px;
}

.nav .scrollbar {
    position: absolute;
    z-index: -1;
    height: calc(100% - 10px);
    top: 5px;
    right: -4px;
    width: 5px;
    background-color: rgba(255,255,255,0);
}

.nav:hover .scrollbar {
    background-color: rgba(255,255,255,0.3);
}

.nav::-webkit-scrollbar {
    display: block;
    width: 5px;
    background: transparent;
}
.nav::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0);
}
.nav::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.5);
    border-right: none;
    border-left: none;
    border-radius: 5px;
    box-shadow: inset 0px 0px 3px rgba(0,0,0,0.2);
}
.nav:hover::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
}
.nav::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 11px; 
}
.nav::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 11px;
}

.nav::-moz-scrollbar {
    display: block;
    width: 5px;
    background: transparent;
}
.nav::-moz-scrollbar-track {
    background: transparent;
}
.nav::-moz-scrollbar-thumb {
    background-color: rgba(255,255,255,0.5);
    border-right: none;
    border-left: none;
    box-shadow: inset 0px 0px 3px rgba(0,0,0,0.2);
}
.nav:hover::-moz-scrollbar-thumb {
    background-color: #FFFFFF;
}
.nav::-moz-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 11px; 
}
.nav::-moz-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 11px;
}

@media only screen and (max-width: 992px) {
    .nav {
        left: 20px;
    }
}

@media only screen and (max-width: 769px) {
    .nav {
        min-height: 500px;
        z-index: 50;
    }
}

@media only screen and (max-width: 412px) {
    .nav {
        left: 10px;
        top: 70px;
        width: 40px;
        border-radius: 5px;
        height: calc(100% - 80px);
    }

    .nav:hover {
        width: 220px;
    }
}