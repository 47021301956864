.input-box {
    margin-bottom: 10px;
}

.input-box label {
    display: block;
    padding: 2px 15px;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 14px;
    position: relative;
}

.input-box label .la-asterisk {
    font-size: 11px;
    display: inline-block;
    vertical-align: text-top;
    color: #FF0066;
}

.input-box label button {
    float: right;
    text-decoration: none;
    line-height: 1; 
    color: #9900CC;
    transition: 0.2s ease all;
    border: none;
    background: transparent;
    cursor: pointer;
}

.input-box label button:hover,
.input-box label button:active {
    text-decoration: underline;
    color: #333333;
}

.input-box .info {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.input-box .info .la {
    font-size: 18px;
    display: block;
    text-align: center;
    width: 18px;
    height: 18px;
    border-radius: 12px;
    background-color: #666666;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.3s;
}

.input-box .info:hover .la {
    background-color: hsl(285, 100%, 25%);
}

.input-box .info .text {
    position: absolute;
    right: 19px;
    top: -50%;
    min-width: 200px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.05);
    height: auto;
    max-height: 70px;
    padding: 10px;
    font-size: 12px;
    letter-spacing: 1px;
    background-color: rgba(0,0,0,0.8);
    color: #F2F2F2;
    font-weight: 300;
    display: none;
}

.input-box .info:hover .text {
    display: block;
}

.input-box input,
.input-box select,
.input-box textarea {
    display: block;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.1);
    background-color: #F2F2F2;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 36px;
    max-height: 65px;
    font-size: 16px;
    line-height: 26px;
    padding: 5px 15px;
    outline: 0;
    font-weight: 400;
    letter-spacing: 1px;
    box-sizing: border-box;
    transition: all 0.2s;
}

.input-box textarea {
    min-height: 65px;
}

.input-box select {
    padding: 5px 15px 5px 10px;
}

.input-box input:focus,
.input-box select:focus,
.input-box textarea:focus {
    background-color: #F9E6FF;
}

.file-input-box {
    height: 38px;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 3px 5px;
    letter-spacing: 1px;
    background-color: #F2F2F2;
    border: 1px solid rgba(0,0,0,0.1);
}

.file-input-box--active {
    background-color: #F9E6FF;
}

.file-input-box__label {
    background-color: rgba(0,0,0,0.05);
    border: 1px solid rgba(0,0,0,0.05);
    color: #666666;
    padding: 3px 6px;
    min-width: 100px;
    text-align: center;
    border-radius: 2px;
    margin-right: 5px;
}

.file-input-box__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #666666;
    font-size: 14px;
}

.file-input-box input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.input-box .invalid-field {
    background-color: #FFCCE0;
    border-color: #FF66A3;
}

.input-box .message {
    font-size: 14px;
    color: #00CC00;
    padding: 2px 5px;
    font-weight: 600;
    line-height: 1.2;
}

.input-box .message.error {
    color: #FF0066;
}

.input-box .message a {
    text-decoration: none;
    color: #9900CC;
}

.input-box .error a:hover {
    text-decoration: underline;
}