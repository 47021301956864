.footer {
    padding: 80px;
    background-color: #F2F2F2;
    color: #666666;
    letter-spacing: 1px;
    font-size: 16px;
}

.footer .service-list {
    width: 38%;
}

.footer .service-list .item {
    padding: 0;
    line-height: 30px;
}

.footer .la {
    color: #00CCFF;
}

.footer .quick-links {
    width: 20%;
}

.footer .quick-links .link {
    text-decoration: none;
    color: #9900CC;
    font-weight: 600;
    padding: 0;
    font-size: 16px;
    border: none;
    background: transparent;
    cursor: pointer;
    margin-bottom: 10px;
}

.footer .brand {
    width: 42%;
    color: #333333;
    line-height: 30px;
}

.footer .brand .logo {
    width: 200px;
    display: inline-block;
}

.footer .brand .tagline {
    font-size: 14px;
    margin: -15px 0 0;
}

.footer .brand a {
    text-decoration: none;
    color: #9900CC;
    font-weight: 600;
}

@media only screen and (max-width: 1025px) {
    .footer .service-list {
        width: 50%;
    }

    .footer .brand {
        width: 50%;
    }
}

@media only screen and (max-width: 992px) {
    .footer {
        padding: 80px 20px;
    }
}

@media only screen and (max-width: 412px) {
    .footer {
        padding: 80px 20px;
        font-size: 14px;
    }

    .footer .service-list {
        width: 100%;
    }

    .footer .brand {
        width: 100%;
        text-align: left;
        margin-top: 50px;
    }
}