@font-face {
  font-family: Avenir;
  src: url(./assets/fonts/Avenir/Avenir-Black.otf);
  font-weight: 800;
}
@font-face {
  font-family: Avenir;
  src: url(./assets/fonts/Avenir/Avenir-Medium.otf);
  font-weight: 600;
}
@font-face {
  font-family: Avenir;
  src: url(./assets/fonts/Avenir/Avenir-Book.otf);
  font-weight: 300;
}
@font-face {
  font-family: Avenir;
  src: url(./assets/fonts/Avenir/Avenir-Roman.otf);
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, button, select, a, textarea {
  outline: none;
  font-family: "Avenir", sans-serif;
}

div {
  float: left;
  width: 100%;
  box-sizing: border-box;
}

.visible-xs, .visible-sm {
  display: none !important;
}

.adsbox {
  display: none;
}

@media only screen and (max-width: 769px) {
  .visible-sm {
    display: unset !important;
  }
  .hidden-sm {
    display: none !important;
  }
}

@media only screen and (max-width: 412px) {
  .visible-xs {
    display: unset !important;
  }
  .hidden-xs {
    display: none !important;
  }
}