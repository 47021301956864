.subscription-box {
    padding: 15px;
}

.subscription {
    padding: 20px;
    letter-spacing: 1px;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
}

.subscription .title {
    font-weight: 700;
    margin: 0 0 20px;
    color: #00A3CC;
}
.subscription .title.green {
    color: #00CC00;
}
.subscription .title.red {
    color: #FF0066;
}

.subscription .trial {
    color: #999999;
    font-weight: 600;
    font-size: 18px;
    margin: -20px 0 20px;
}

.subscription .benefits {
    width: 70%;
    padding: 20px 0;
}

.subscription .benefits.wide {
    width: 100%;
}

.subscription .benefits.back {
    border-radius: 5px;
    padding: 10px 20px;
    background-image: linear-gradient(135deg, #007A99, #730099);
}

.subscription .benefits h3 {
    font-weight: 600;
    margin: 0 0 20px;
}

.subscription .benefits.back h3 {
    color: #FFFFFF;
}

.subscription .benefits .item {
    position: relative;
    padding: 15px 10px 15px 30px;
    color: #333333;
    font-size: 14px;
}

.subscription .benefits .item .la {
    position: absolute;
    font-size: 24px;
    left: 0;
    top: 10px;
    color: #9900CC;
}

.subscription .benefits .desc {
    width: 33.333333%;
    margin: 10px 0;
    color: #FFFFFF;
    font-size: 26px;
    font-weight: 700;
}

.subscription .benefits .label {
    color: #E6E6E6;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 2px;
}

.subscription .plans {
    width: 30%;
    min-height: 300px;
    padding: 20px 20px 0;
    text-align: center;
    border-radius: 10px;
    background-color: #F9F9F9;
}

.subscription .plans h3 {
    font-weight: 600;
    margin: 0 0 20px;
}

.subscription .plans .plan {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #9900CC;
    background-image: linear-gradient(135deg, #007A99, #730099);
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
}

.subscription .plans .plan .price {
    font-weight: 700;
    margin: 0;
    font-size: 34px;
    color: #FFFFFF;
}

.subscription .plans .plan .period {
    color: #E6E6E6;
    font-weight: 300;
    margin: 0;
}

.subscription .plans .plan button {
    background-color: #FFFFFF;
    color: #9900CC;
    padding: 10px 30px;
    min-width: 80%;
    letter-spacing: 1px;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    cursor: pointer;
    transition: all 0.2s;
}

.subscription .plans .plan button:hover {
    background-color: rgba(255,255,255,0.9);
}

@media only screen and (max-width: 992px) {
    .subscription .benefits {
        width: 65%;
    }

    .subscription .plans {
        width: 35%;
    }
}

@media only screen and (max-width: 769px) {
    .subscription .benefits {
        width: 100%;
    }

    .subscription .plans {
        width: 100%;
        padding: 20px 10px;
    }

    .subscription .plans .plan {
        width: calc(50% - 20px);
        margin: 10px;
    }

    .subscription .benefits.back {
        margin-top: 10px;
        padding: 10px;
    }

    .subscription .benefits .desc {
        width: 50%;
    }
}

@media only screen and (max-width: 412px) {
    .subscription {
        padding: 10px;
    }

    .subscription .title {
        font-size: 20px;
        margin: 0;
    }

    .subscription .trial {
        font-size: 16px;
        margin: 5px 0 20px;
        font-weight: 400;
    }

    .subscription .benefits .item {
        padding: 5px 10px 5px 25px;
    }

    .subscription .benefits .item .la {
        font-size: 20px;
        left: 0;
        top: 5px;
    }

    .subscription .plans .plan {
        width: 100%;
        margin: 0 0 20px;
    }

    .subscription .benefits h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .subscription .benefits .desc {
        width: 100%;
        margin: 5px 0;
        font-size: 18px;
    }
    
    .subscription .benefits .label {
        font-size: 14px;
    }
}

.payment-form {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: -100%;
    z-index: 100;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.2s;
}

.payment-form.active {
    top: 0;
    background-color: rgba(0,0,0,0.3);
}

.payment-form .form {
    display: none;
    float: unset;
    width: 300px;
    height: 400px;
    margin: auto;
    position: relative;
    top: -400px;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    overflow: hidden;
    padding: 50px 20px 20px;
}

.payment-form .form.error,
.payment-form .form.success {
    font-size: 16px;
    line-height: 1.8;
    font-weight: 600;
    color: #FF0066;
    letter-spacing: 1px;
    text-align: center;
}
.payment-form .form.success {
    font-size: 18px;
    font-weight: 800;
    color: #00CC00;
}

.payment-form .form.active {
    display: block;
}

.payment-form.active .form {
    top: 100px;
}

.payment-form .form .message {
    font-weight: 300;
    font-size: 16px;
    color: #999999;
    margin-top: 15px;
}

.payment-form .form .message b {
    color: #333333;
}

.payment-form .form label {
    display: block;
    margin-bottom: 3px;
    font-size: 14px;
}

.payment-form .form .item {
    padding: 5px;
    margin: 0;
}

.payment-form .form .item.w-75 {
    width: 75%;
}
.payment-form .form .item.w-50 {
    width: 50%;
}
.payment-form .form .item.w-25 {
    width: 25%;
}

.payment-form .form input {
    display: block;
    margin: 0;
    border: 1px solid #CCCCCC;
    width: 100%;
    font-size: 16px;
    padding: 8px;
    box-sizing: border-box;
}

.payment-form .form button {
    display: block;
    margin: 10px auto 0;
    min-width: 70%;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    border-radius: 3px;
    border: none;
    background-color: #9900CC;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
    transition: all 0.2s;
}

.payment-form .form button:hover {
    background-color: #730099;
}

.payment-form .form button.cancel {
    background-color: transparent;
    box-shadow: none;
    color: #730099;
}

.payment-form .form button.cancel:hover {
    background-color: #F2F2F2;
}

.payment-form .form .paystack-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #F9F9F9;
    padding: 10px;
}

.payment-form .form .paystack-header img {
    display: block;
    height: 20px;
    margin: auto;
}

.payment-form .form .paystack-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #F9F9F9;
    padding: 15px;
}

.payment-form .form .paystack-footer img {
    display: block;
    height: 70px;
    margin: auto;
}

.payment-message {
    position: fixed;
    top: 100px;
    font-weight: 800;
    font-size: 30px;
    color: #00CC00;
    height: 420px;
    width: 300px;
    left: 50%;
    z-index: 110;
    margin-left: -150px;
    border-radius: 5px;
    padding: 50px 30px;
    overflow: hidden;
    background-color: white;
}