.breadcrumb {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #999999;
    padding: 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.breadcrumb .go-back {
    background-color: #FFFFFF;
    border-radius: 3px;
    border: none;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.1);
    height: 30px;
    width: 30px;
    font-size: 20px;
    color: #CCCCCC;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    transition: all 0.2s;
}

.breadcrumb .go-back:hover {
    background-color: #F2F2F2;
    color: #666666;
}

@media only screen and (max-width: 992px) {
    .breadcrumb {
        font-size: 26px;
        padding: 0 10px;
    }
}

@media only screen and (max-width: 412px) {
    .breadcrumb {
        font-size: 20px;
    }
}