.header {
    background-color: #FFFFFF;
    padding: 20px 80px 16px;
    position: fixed;
    z-index: 150;
    max-height: 80px;
    box-shadow: 0px 2px 5px rgba(77,0,102,0.1);
}

.header-logo {
    width: 30%;
}

.header-logo img {
    height: 40px;
}

.header .menu {
    width: 40%;
    height: 40px;
    text-align: center;
}

.header .sess-menu {
    width: 30%;
    text-align: right;
}

.header .menu-link {
    display: inline-block;
    text-decoration: none;
    color: #333333;
    transition: all 0.2s;
    padding: 10px;
    margin: 0 5px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
}

/* .header .menu-link.active {
    color: #00B8E6;
} */

.header .menu-link.b {
    color: #00B8E6;
    margin: 0px 20px;
}

.header .menu-link:hover {
    color: #007A99;
}

.header .menu-link.c {
    color: #00B8E6;
    background-color: #FFFFFF;
    border: 2px solid #00B8E6;
    border-radius: 3px;
    padding: 8px 25px;
}

.header .menu-link.c:hover {
    text-decoration: none;
    color: #FFFFFF;
    background-color: #00B8E6;
}

@media only screen and (max-width: 992px) {
    .header {
        padding: 20px 20px 16px;
    }
}

@media only screen and (max-width: 769px) {
    .header-logo {
        width: 50%;
    }
    
    .header .menu {
        display: none;
    }

    .header .sess-menu {
        width: 50%;
    }
}

@media only screen and (max-width: 412px) {
    .header {
        padding: 10px 10px 6px;
        width: 100%;
        max-height: 60px;
        box-shadow: 0px 0px 2px rgba(0,0,0,0.1);
    }

    .header-logo {
        width: 40%;
    }

    .header-logo img {
        height: 30px;
        margin: 5px 0;
    }
}

.header .sess-user {
    display: inline-block;
    width: auto;
    float: none;
    position: relative;
    margin: -15px -30px 0 0;
    padding: 20px;
    height: 65px;
    text-align: left;
    border-radius: 5px;
    transition: all 0.3s;
    box-shadow: none;
    overflow: hidden;
}

.header .sess-user:hover {
    background-color: #F9E6FF;
    height: 120px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
}

.header .sess-user .la {
    font-size: 30px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
}

.header .sess-user .lynk {
    text-decoration: none;
    display: block;
    color: inherit;
    letter-spacing: 1px;
    border: none;
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    background-color: transparent;
    cursor: pointer;
    margin: 5px 0;
    padding: 15px 10px;
    transition: all 0.3s;
}

.header .sess-user .lynk:hover {
    color: #9900CC;
}

.header .sess-user .lynk.a {
    font-size: 18px;
    color: #9900CC;
    font-weight: 600;
    margin: 0;
    padding: 0 10px;
    position: relative;
}

.header .sess-user:hover .lynk.a {
    color: #333333;
}

.header .sess-user .lynk.a:hover {
    color: #9900CC;
}

.header .sess-user .lynk.a span {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.header .sess-user .lynk.b {
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-top: 10px;
    display: none;
}

.header .sess-user:hover .lynk.b {
    display: block;
}

@media only screen and (max-width: 769px) {
    .header .sess-user {
        margin: -13px -30px 0 0;
        padding: 20px;
        height: 65px;
        width: 50%;
    }

    .header .sess-user .lynk {
        display: inline-block;
        padding: 0 20px;
    }

    .header .sess-user .lynk.a span {
        max-width: calc(100% - 40px);
    }

    .header .sess-user .lynk.b {
        border-top: none;
        margin: 0;
        font-size: 18px;
        display: inline-block;
    }
}

@media only screen and (max-width: 412px) {    
    .header .sess-menu {
        width: 60%;
    }

    .header .sess-user {
        margin: -10px -15px 0 0;
        padding: 20px 10px 20px 0;
        width: 100%;
        text-align: right;
    }
    
    .header .sess-menu .menu-link.b {
        margin: 0;
    }
    
    .header .sess-menu .menu-link.c {
        display: none;
    }

    .header .sess-user:hover {
        background-color: #FFFFFF;
        height: 60px;
        box-shadow: none;
    }

    .header .sess-user .lynk {
        padding: 0 5px;
    }

    .header .sess-user .lynk.a {
        font-size: 14px;
        padding: 0 5px;
    }

    .header .sess-user:hover .lynk.a {
        color: #9900CC;
    }

    .header .sess-user .lynk.a span {
        max-width: 120px;
    }

    .header .sess-user .lynk.b {
        font-size: 14px;
    }

    .header .sess-user:hover .lynk.b {
        display: inline-block;
    }
    
    .header .sess-user .la {
        display: none;
    }
}