.text-box {
    padding: 15px;
}

.text-box .text {
    min-height: 200px;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 2;
    font-weight: 300;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
    transition: all 0.3s;
}

@media only screen and (max-width: 992px) {
    .text-box {
        padding: 10px;
    }
}

@media only screen and (max-width: 769px) {
    .text-box .text {
        border-radius: 5px;
        font-size: 16px;
    }
}