.alt-modal-backdrop {
    position: fixed;
    top: -110%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
}

.alt-modal-backdrop.active {
    top: 0;
}

.alt-modal {
    min-height: calc(100% - 120px);
    width: 90%;
    margin: 100px 5% 20px;
    transform: translateY(-400px);
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0px 15px 40px rgba(0,0,0,0.3);
    transition: all 0.3s ease-out;
    position: relative;
    overflow: hidden;
    border-bottom: 10px solid #f2f2f2;
}

.alt-modal.active {
    transform: translateY(0);
}

.alt-modal-heading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 25px;
    margin: 0;
    text-align: center;
    color: #600080;
    padding: 10px;
    box-sizing: border-box;
    height: 58px;
    background-color: #F9F9F9;
    border-bottom: 1px solid #F2F2F2;
}

.alt-modal-heading .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    border: 0;
    font-size: 20px;
    cursor: pointer;
}

.alt-modal-heading .close .la {
    color: #600080;
}

.alt-modal-content {
    margin-top: 58px;
    padding: 30px 40px;
    height: calc(100% - 58px);
    position: absolute;
    overflow-y: auto;
    color: #101010;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: left;
}
.alt-modal-content.b {
    height: calc(100% - 108px);
}

.alt-modal-content::-webkit-scrollbar {
    width: 12px;
    background-color: #CCF5FF;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
}
.alt-modal-content::-webkit-scrollbar-thumb {
    background-color: #00CCFF;
    /* border-radius: 6px; */
    box-shadow: inset 0px 0px 3px rgba(0,0,0,0.2);
}
.alt-modal-content::-moz-scrollbar {
    width: 12px;
    background-color: #CCF5FF;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
}
.alt-modal-content::-moz-scrollbar-thumb {
    background-color: #00CCFF;
    box-shadow: inset 0px 0px 3px rgba(0,0,0,0.2);
}

.alt-modal-footer {
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 5px 10px;
    background-color: #F9F9F9;
    border-top: 1px solid #F2F2F2;
}

.alt-modal-footer button {
    height: 100%;
    font-size: 18px;
    border: none;
    background-color: #600080;
    border-radius: 5px;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 1px;
    min-width: 150px;
    cursor: pointer;
}

@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 769px) {

}

@media only screen and (max-width: 412px) {
    .alt-modal-heading {
        font-size: 20px;
        padding-top: 15px;
        padding-right: 50px;
    }

    .alt-modal-content {
        padding: 20px;
    }
}

/* FAQs */
.faq-item {
    margin-bottom: 10px;
    border: 2px solid #600080;
    border-radius: 5px;
    overflow: hidden;
}

.faq-question {
    display: block;
    text-align: left;
    width: 100%;
    background-color: #FFFFFF;
    color: #600080;
    border: none;
    padding: 10px 20px 10px 10px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}
.faq-question:hover {
    color: #FFFFFF;
    background-color: #600080;
}

.faq-item.active .faq-question {
    background-color: #600080;
    color: #FFFFFF;
}

.faq-question .la {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #600080;
    transition: all 0.3s ease;
}

.faq-question:hover .la {
    color: #FFFFFF;
}

.faq-item.active .faq-question .la {
    color: #FFFFFF;
    transform: translateY(-50%) rotate(180deg);
}

.faq-answer {
    padding: 0px 20px;
    background-color: #F9F9F9;
    transition: all 0.3s ease;
    max-height: 0;
}

.faq-item.active .faq-answer {
    max-height: 500px;
    transition: max-height 0.6s ease;
    padding: 10px 20px;
}

@media only screen and (max-width: 412px) {
    .faq-question .la {
        right: 5px;
    }

    .faq-answer {
        padding: 0px 10px;
    }

    .faq-item.active .faq-answer {
        padding: 10px;
    }
}

/* PRIVACY */
.privacy-item-heading {
    color: #600080;
}

.privacy-item ol {
    list-style-type: lower-roman;
}

/* TERMS */
.terms-item ul {
    list-style-type: '-   ';
}

.terms-item ol.roman {
    list-style-type: lower-roman;
}