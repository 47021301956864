.page-not-found {
    background-color: transparent;
    background-image: url(../../assets//images/illustrations/404.svg);
    background-position: center top;
    background-size: auto 100%;
    background-repeat: no-repeat;
    text-align: center;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100vh - 80px);
    z-index: 50;
}

.page-not-found h1 {
    font-size: 280px;
    margin: 70px 0 0;
    line-height: 1;
    color: #00CCFF;
    text-shadow: 0px 10px 3px rgba(0,0,0,0.1);
}

.page-not-found h4 {
    margin: 0 0 60px;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 1px;
    text-shadow: 0px 5px 3px rgba(0,0,0,0.1);
}

.page-not-found .link {
    padding: 0;
    margin: 0 20px;
}

.page-not-found .link:hover {
    text-decoration: underline;
}

