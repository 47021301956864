.list-box {
    padding: 15px;
}

.list {
    height: calc(100vh - 140px);
    padding: 80px 5px 60px 10px;
    overflow: hidden;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
    position: relative;
}

/* Widths */
.list-box.w-1of3 {
    width: 33.33333333%;
}
.list-box.w-1of2 {
    width: 50%;
}

/* List content widths */
.list .w-10 {
    width: 10%;
}
.list .w-15 {
    width: 15%;
}
.list .w-20 {
    width: 20%;
}
.list .w-25 {
    width: 25%;
}
.list .w-30 {
    width: 30%;
}
.list .w-35 {
    width: 35%;
}
.list .w-40 {
    width: 40%;
}
.list .w-45 {
    width: 45%;
}
.list .w-50 {
    width: 50%;
}
.list .w-60 {
    width: 60%;
}
.list .w-70 {
    width: 70%;
}

@media only screen and (max-width: 992px) {
    .list-box {
        padding: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .list {
        border-radius: 5px;
    }
}

@media only screen and (max-width: 412px) {
    .list {
        padding: 40px 5px 60px 5px;
        height: calc(100vh - 100px);
    }

    .list .w-10, .list .w-15, .list .w-20, .list .w-25,
    .list .w-30, .list .w-40, .list .w-45, .list .w-50 {
        width: 100%;
    }
}

/* List Header */
.list-head {
    width: unset;
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.list-name {
    font-size: 20px;
    font-weight: 600;
    color: #730099;
    height: 35px;
    letter-spacing: 1px;
    padding: 10px 10px 0;
    background-color: #F9F9F9;
}

.search-box {
    float: right;
    width: auto;
    padding: 0 5px;
    margin-top: -7px;
}

.search-box .filter {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    text-align: center;
    line-height: 27px;
    padding-top: 3px;
    border: 1px solid #CCCCCC;
    background-color: #F9E6FF;
    color: #9900CC;
    position: relative;
    transition: all 0.2s;
}

.search-box .filter:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.search-box .filter.active {
    width: auto;
    padding: 2px 5px 0;
    color: #F9E6FF;
    border: 1px solid transparent;
    background-color: #9900CC;
}

.search-box .filter .la {
    display: inline-block;
    width: 28px;
    font-size: 20px;
    cursor: pointer;
}

.search-box .filter.active .la {
    font-size: 18px;
}

.search-box .filter .la-redo-alt {
    display: none;
}

.search-box .filter.active .la-filter {
    color: rgba(255,255,255,0.5);
}

.search-box .filter.active .la-redo-alt {
    display: inline-block;
}

.search-box .filter-list {
    position: absolute;
    top: 29px;
    right: 0;
    margin-left: -200px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 400;
    text-align: left;
    padding: 0 5px;
    width: 200px;
    height: 0px;
    border-radius: 5px;
    color: #333333;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s;
}

.search-box .filter:hover .filter-list {
    height: 200px;
}

.search-box .filter-list::-webkit-scrollbar {
    width: 5px;
    background-color: #E6FAFF;
    border-radius: 6px;
}
.search-box .filter-list::-webkit-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
}
.search-box .filter-list::-moz-scrollbar {
    width: 5px;
    background-color: #E6FAFF;
    border-radius: 6px;
}
.search-box .filter-list::-moz-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
}

.search-box .filter-list form input {
    width: calc(100% - 16px);
    height: 25px;
    letter-spacing: 1px;
    padding: 0px 10px;
    border: 1px solid #999999;
    border-radius: 3px;
    margin: auto;
    display: block;
    box-sizing: border-box;
}

.search-box .filter-list form button {
    background-color: #9900CC;
    color: #FFFFFF;
    letter-spacing: 1px;
    font-family: Avenir;
    border: none;
    border-radius: 2px;
    display: block;
    width: auto;
    margin: 5px auto 0;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.2s;
}

.search-box .filter-list form button:hover {
    background-color: #730099;
}

.search-box .filter-list h3 {
    margin: 5px 0 5px;
    padding: 0px 5px;
    font-size: 14px;
    border-bottom: 1px solid #F2F2F2;
}

.search-box .filter-list .item {
    padding: 1px 20px 1px 8px;
    display: block;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.2s;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
}

.search-box .filter-list .item:hover {
    background-color: #F9E6FF;
}

.search-box .filter-list .item input {
    position: absolute;
    right: 5px;
    top: 5px;
}

.search-box .list-search {
    width: 300px;
    height: 30px;
    float: none;
    display: inline-block;
    margin-left: 20px;
    padding: 1px 0 0;
}

.search-box .list-search input {
    width: calc(100% - 50px);
    height: 20px;
    padding: 0 5px;
    letter-spacing: 1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid #CCCCCC;
}

.search-box .list-search button {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: 18px;
    padding: 0;
    border: 1px solid #CCCCCC;
    background-color: #F9E6FF;
    color: #9900CC;
    transition: all 0.2s;
    cursor: pointer;
}

.search-box .list-search button:disabled {
    opacity: 0.4;
    cursor: default;
}

.list-header {
    background-color: #F2F2F2;
    font-weight: 600;
    color: #000000;
    font-size: 15px;
    padding: 6px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

@media only screen and (max-width: 769px) {
    .search-box .list-search {
        width: 200px;
    }
    .search-box .list-search input {
        width: calc(100% - 30px);
    }
}

@media only screen and (max-width: 412px) {
    .search-box {
        padding: 0;
    }
}

/* List items */
.list-item-box {
    height: 100%;
    padding: 15px 0 0 5px;
    overflow-y: scroll;
    scrollbar-color: #00CCFF #E6FAFF;
    scrollbar-width: thin;
}

.list-item-box::-webkit-scrollbar {
    width: 5px;
    background-color: #E6FAFF;
    border-radius: 6px;
}
.list-item-box::-webkit-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
}
.list-item-box::-moz-scrollbar {
    width: 5px;
    background-color: #E6FAFF;
    border-radius: 6px;
}
.list-item-box::-moz-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
}

.list-item-box .no-item {
    text-align: center;
    font-size: 20px;
    letter-spacing: 1px;
    padding: 50px 30px;
    color: #999999;
    font-weight: 300;
}

.list-item {
    width: 100%;
    float: left;
    padding: 3px 10px 3px 5px;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 44px;
    font-weight: 300;
    transition: all 0.3s;
    letter-spacing: 1px;
    border-bottom: 1px solid #E6FAFF;
    box-shadow: none;
    cursor: pointer;
    color: #1A1A1A;
}

.list-item:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.07);
}

.list-item-name,
.list-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.list-item-not-ready {
    color: #999999;
}

.list-item-score {
    position: relative;
    width: calc(100% - 60px);
    margin-right: 5px;
}

.list-item-score .score-meter {
    display: inline-block;
    background-color: pink;
    height: 10px;
    border-radius: 5px;
    box-shadow: inset 0px 0px 5px rgba(0,0,0,0.1);
    width: 0%;
    transition: all 1s ease-out;
}
.list-item-score .score-meter.purple {
    background-color: #9900CC;
}
.list-item-score .score-meter.cyan {
    background-color: #00A3CC;
}
.list-item-score .score-meter.red {
    background-color: #FF0066;
}
.list-item-score .score-meter.green {
    background-color: #00CC00;
}
.list-item-score .score-meter.gray {
    background-color: #F2F2F2;
    box-shadow: none;
}

.list-item-score-value.purple {
    color: #9900CC;
}
.list-item-score-value.cyan {
    color: #00A3CC;
}
.list-item-score-value.red {
    color: #FF0066;
}
.list-item-score-value.green {
    color: #00CC00;
}
.list-item-score-value.gray {
    color: #FF0066;
}

.list-actions {
    text-align: right;
}

.list-actions button {
    height: 34px;
    width: 34px;
    border: none;
    border-radius: 3px;
    font-size: 20px;
    margin: 0px 5px 0;
    background-color: transparent;
    transition: all 0.2s;
    cursor: pointer;
    transition: all 0.2s;
}

.list-actions .verify {
    color: #E699FF;
}
.list-item:hover .list-actions .verify {
    color: #9900CC;
    background-color: #F9E6FF;
}

.list-actions .edit {
    color: #99EBFF;
}
.list-item:hover .list-actions .edit {
    color: #00A3CC;
    background-color: #E6FAFF;
}

.list-actions .trash {
    color: #CCCCCC;
}
.list-item:hover .list-actions .trash {
    color: #333333;
    background-color: #F2F2F2;
}

@media only screen and (max-width: 412px) {
    .list-item-box {
        height: 100%;
        padding: 15px 0 0 2px;
    }
    .list-item {
        padding: 15px 10px 15px 5px;
        font-size: 15px;
        line-height: 1.6;
    }

    .list-item-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        max-height: 100px;
    }
}

.list-pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-color: #F2F2F2;
    text-align: center;
    padding: 7px 20px;
}

.list-pagination button {
    height: 36px;
    min-width: 36px;
    cursor: pointer;
    background-color: #FFFFFF;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    font-size: 14px;
    margin: 0 4px;
    padding: 0px 10px;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
    transition: all 0.2s;
}

.list-pagination button:hover {
    background-color: #FFFFFF;
    color: #9900CC;
    box-shadow: none;
}

.list-pagination button.active {
    background-color: #9900CC;
    color: #FFFFFF;
    cursor: default;
}

.list-pagination button .la {
    font-size: 12px;
}

@media only screen and (max-width: 412px) {
    .list-pagination {
        height: 40px;
        padding: 7px 20px;
    }
    
    .list-pagination button {
        height: 26px;
        min-width: 26px;
        font-size: 14px;
        margin: 0 4px;
        padding: 0px 10px;
    }
}