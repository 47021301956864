.dashboard {
    height: calc(100vh - 80px);
    margin-top: 80px;
    padding: 20px 80px 20px 200px;
    position: relative;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #f9f9f9;
    scrollbar-color: #00CCFF #CCF5FF;
    scrollbar-width: auto;
}

.dashboard::-webkit-scrollbar {
    width: 12px;
    background-color: #CCF5FF;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
}
.dashboard::-webkit-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
    box-shadow: inset 0px 0px 3px rgba(0,0,0,0.2);
}
.dashboard::-moz-scrollbar {
    width: 12px;
    background-color: #CCF5FF;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
}
.dashboard::-moz-scrollbar-thumb {
    background-color: #00CCFF;
    border-radius: 6px;
    box-shadow: inset 0px 0px 3px rgba(0,0,0,0.2);
}

@media only screen and (max-width: 1025px) {
    .dashboard {
        padding: 20px 10px 20px 170px;
    }
}

@media only screen and (max-width: 992px) {
    .dashboard {
        padding: 20px 10px 20px 110px;
    }
}

@media only screen and (max-width: 769px) {
    .dashboard {
        min-height: 550px;
        background-size: 60% auto;
    }
}

@media only screen and (max-width: 412px) {
    .dashboard {
        padding: 10px 0px 20px 50px;
        margin-top: 60px;
        height: calc(100vh - 60px);
    }
    .dashboard::-webkit-scrollbar {
        width: 6px;
    }
    .dashboard::-moz-scrollbar {
        width: 6px;
    }
}


.dashform-box {
    padding: 15px;
    float: left;
    width: 100%;
}

.dashform-box.w-40 {
    width: 40%;
}

.dashform {
    min-height: calc(100vh - 180px);
    padding: 20px 20px 80px;
    float: left;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    text-decoration: none;
    background-color: rgba(255,255,255,0.95);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
    transition: all 0.3s;
}

.dashform.update-profile {
    width: 100%;
    padding: 20px 20px 40px;
}

.dashform-intro {
    padding: 30px 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #F9F9F9;
    margin: -20px 0 20px;
    font-size: 22px;
    letter-spacing: 1px;
    color: #600080;
    font-weight: 300;
    position: relative;
}

.dashform-intro.text-center {
    text-align: center;
}

.dashform-intro.b {
    background-color: #600080;
    color: #E6E6E6;
    padding: 15px 20px 40px;
    font-size: 20px;
}

.update-profile .dashform-intro {
    background-color: #600080;
    color: #E6E6E6;
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 400;
}

.dashform-intro.b b {
    color: #FFFFFF;
}

.dashform-intro .form-nav {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: rgba(255,255,255,0.4);
    background-color: rgba(0,0,0,0.1);
}

.dashform-intro .form-nav span {
    display: inline-block;
    width: 50%;
    text-align: center;
}
.dashform-intro .form-nav span.active {
    color: #FFFFFF;
}

.dashform .health-score {
    font-size: 70px;
    color: #730099;
    text-align: center;
}

.dashform .health-score .message {
    font-size: 40px;
    display: block;
    color: #FF0066;
}

.dashform .health-score .message .green {
    color: #00CC00;
}

.dashform .health-score .message .blue {
    color: #00A3CC;
}

.dashform .submit-box {
    margin-top: 20px;
}

.dashform .submit-box.dash-input-submit {
    width: 40%;
    margin-top: 40px;
}

.dashform .submit-box .form-nav {
    background-color: #FFFFFF;
    margin-top: 2px;
    padding: 8px 20px;
    display: inline-block;
    min-width: 100px;
    border: 2px solid #00CCFF;
    border-radius: 3px;
    color: #00CCFF;
    font-size: 16px;
    letter-spacing: 1px;
    outline: 0;
    font-weight: 600;
    transition: all 0.2s;
    cursor: pointer;
}
.dashform .submit-box .right {
    float: right;
}

.dashform .submit-box .form-nav:disabled {
    color: #CCF5FF;
    border-color: #CCF5FF;
    cursor: default;
}

.dashform .submit-box .form-nav:not(:disabled):hover {
    background-color: #B3F0FF;
}

.dashform .submit-box .submit {
    background-color: #730099;
    padding: 10px 25px;
    margin: 0;
    min-width: 150px;
    border: none;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 1px;
    outline: 0;
    font-weight: 600;
    transition: all 0.2s;
    cursor: pointer;
}

.dashform .submit-box .submit:hover {
    background-color: #600080;
    transition: all 0.2s;
}

.dashform .submit-box .submit:active {
    background-color: #4D0066;
}

.dashform .submit-box .submit:disabled {
    background-color: #ECB3FF;
    cursor: default;
}

.dashform .submit-box .center-button {
    margin: 20px 0 -20px;
    text-align: center;
}

.dashform .submit-box .center-button .form-nav {
    color: #FFFFFF;
    background-color: #00CCFF;
}
.dashform .submit-box .center-button .form-nav:hover {
    border-color: #00A3CC;
    background-color: #00A3CC;
}

.dashform .dashform-message {
    margin-top: 30px;
    text-align: center;
    color: #666666;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 1px;
}

@media only screen and (max-width: 769px) {
    .dashform {
        width: 70%;
    }

    .dashform .submit-box.dash-input-submit {
        width: 100%;
    }

    .dashform-box.w-40 {
        width: 70%;
    }
}

@media only screen and (max-width: 412px) {
    .dashform {
        width: 100%;
        min-height: calc(100vh - 180px);
        padding: 10px 10px 40px;
    }

    .dashform-box.w-40 {
        width: calc(100% - 25px);
    }
}