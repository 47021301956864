.chart-box {
    padding: 15px;
}

.chart-container {
    padding: 40px 20px 20px;
    border-radius: 10px;
    background-color: rgba(255,255,255,1);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.07);
    position: relative;
}

.chart-name {
    width: unset;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #730099;
    padding: 5px 10px;
    background-color: #F9F9F9;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

@media only screen and (max-width: 992px) {
    .chart-box {
        padding: 10px;
    }
}

@media only screen and (max-width: 412px) {
    .chart-container {
        padding: 40px 10px 20px;
        border-radius: 5px;
    }
    
    .chart-name {
        left: 10px;
        right: 10px;
        font-size: 16px;
        padding: 5px 10px;
    }
}

.chart {
    width: 50%;
    text-align: center;
    padding-right: 5px;
}

.chart .w-25 {
    width: 25%;
}

.chart-values .item {
    padding: 0 5px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    color: #9900CC;
    background-color: #F9E6FF;
}

.chart-values .item.gray {
    color: #333333;
    background-color: #F2F2F2;
}

.chart-bars {
    margin-top: 5px;
    min-height: 180px;
}

.no-chart-bars {
    margin-top: 5px;
    min-height: 180px;
    color: #999999;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.5;
    font-size: 16px;
    padding: 20px;
}

.no-chart-bars .check {
    color: #333333;
    text-decoration: none;
    font-weight: 600;
}

.no-chart-bars .check:hover {
    text-decoration: underline;
}

.chart-bars .item {
    position: relative;
}

.chart-bars .item .bar {
    float: none;
    height: 180px;
    width: 40px;
    margin: 0px auto;
    background-color: #F2F2F2;
    position: relative;
}

.chart-bars .item .bar .meter {
    position: absolute;
    bottom: 0;
    left: 3px;
    width: 34px;
    height: 0%;
    transition: all 0.3s;
    background-color: #9900CC;
}

.chart-bars .item .no-bar {
    color: #999999;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.4;
    padding: 10px 5px;
    font-weight: 300;
}

.chart-bars .item .no-bar b {
    color: #666666;
}

.chart-bars .item .no-bar .check {
    color: #000000;
    font-weight: 600;
    text-decoration: none;
}

.chart-bars .item .no-bar .check:hover {
    text-decoration: underline;
}

.chart-labels .item {
    padding: 9px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    height: 50px;
    letter-spacing: 1px;
    color: #9900CC;
    background-color: #F2F2F2;
    border-top: 1px solid #9900CC;
}

@media only screen and (max-width: 1025px) {
    .chart {
        width: 65%;
    }
}

@media only screen and (max-width: 769px) {
    .chart {
        width: 65%;
    }

    .chart-labels .item {
        height: 75px;
    }
}

@media only screen and (max-width: 412px) {
    .chart {
        width: 100%;
    }
    
    .chart-labels .item {
        height: 35px;
    }
}

.chart-total {
    width: 50%;
    padding: 40px 20px;
    text-align: center;
    font-size: 20px;
    color: #999999;
    letter-spacing: 1px;
    font-weight: 300;
}

.chart-total .date {
    color: #9900CC;
    font-weight: 600;
}

.chart-total h1 {
    margin: 10px 0;
    font-size: 80px;
    color: #FF0066;
}

.chart-total h3 {
    margin: 20px 0 15px;
    font-size: 60px;
    color: #333333;
}

.chart-total .link {
    line-height: 1;
    margin-top: 10px;
}

.chart-total .sub-message {
    margin: 0 0 30px;
    color: #FF0066;
}

@media only screen and (max-width: 1025px) {
    .chart-total {
        width: 35%;
        padding: 20px 20px;
    }

    .chart-total .link {
        padding: 15px 5px;
        width: 100%;
    }
}

@media only screen and (max-width: 769px) {
    .chart-total {
        width: 35%;
        font-size: 16px;
    }

    .chart-total h1 {
        font-size: 70px;
    }
}

@media only screen and (max-width: 412px) {
    .chart-total {
        width: 100%;
        padding: 20px 20px;
    }

    .chart-total .link {
        margin-top: 0;
        padding: 10px 30px;
        width: auto;
        font-size: 14px;
    }
}